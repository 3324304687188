import React, { useEffect, useState } from 'react';
import { Editor, EditorState, RichUtils, convertFromRaw, convertToRaw, ContentState } from 'draft-js';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import './FeedbackEditor.css'; 
import Navbar from './Navbar';
import { FaBold, FaItalic, FaUnderline, FaListUl, FaListOl, FaUndo, FaRedo } from 'react-icons/fa';

// Helper function to check if a string is valid JSON
const isValidJSON = (str) => {
    try {
        JSON.parse(str);
        return true;
    } catch (e) {
        return false;
    }
};

const FeedbackEditor = () => {
    const { submissionID } = useParams();  // Get submissionID from the URL
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [feedbackKey, setFeedbackKey] = useState('');
    const navigate = useNavigate();

    // Fetch feedback content from S3 when the component is loaded
    useEffect(() => {
        const fetchFeedback = async () => {
            try {
                // Fetch the feedback key associated with the submission
                const response = await axios.get(`https://jkcme8yg4d.execute-api.eu-north-1.amazonaws.com/FetchFeedback?submissionID=${submissionID}`);
                const key = response.data.feedbackKey;
                setFeedbackKey(key);

                // Fetch the feedback content from S3 using the feedback key with cache-busting
                const feedbackResponse = await axios.get(`https://s3-eu-north-1.amazonaws.com/feedbacks-bucket/${key}?t=${new Date().getTime()}`);
                const feedbackContent = feedbackResponse.data;

                // Check if the feedback content is valid JSON
                if (isValidJSON(feedbackContent)) {
                    const contentState = convertFromRaw(JSON.parse(feedbackContent));  // Parse as JSON for Draft.js
                    setEditorState(EditorState.createWithContent(contentState));
                } else {
                    // If the content is plain text, treat it as plain text
                    const plainTextContent = ContentState.createFromText(feedbackContent);
                    setEditorState(EditorState.createWithContent(plainTextContent));
                }
            } catch (error) {
                setError('Failed to load feedback.');
                console.error('Error fetching feedback:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchFeedback();
    }, [submissionID]);

    // Custom handler for key commands
    const handleKeyCommand = (command) => {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            setEditorState(newState);
            return 'handled';
        }
        return 'not-handled';
    };

    // Apply inline styles (bold, italic, underline)
    const toggleInlineStyle = (style) => {
        setEditorState(RichUtils.toggleInlineStyle(editorState, style));
    };

    // Apply block types (unordered/ordered lists)
    const toggleBlockType = (blockType) => {
        setEditorState(RichUtils.toggleBlockType(editorState, blockType));
    };

    // Save the content
    const handleSaveClick = async () => {
        const contentState = editorState.getCurrentContent();
        const rawContent = JSON.stringify(convertToRaw(contentState));  // Convert Draft.js content to raw JSON format

        try {
            // Call the SaveFeedback API with the updated content
            await axios.put('https://o79c7cjoi6.execute-api.eu-north-1.amazonaws.com/SaveFeedback', {
                submissionID,  // Include submissionID to identify which feedback to update
                feedback: rawContent  // Pass the updated feedback content as raw JSON
            });

            // Refetch the feedback from S3 to ensure the latest version is displayed
            const feedbackResponse = await axios.get(`https://s3-eu-north-1.amazonaws.com/feedbacks-bucket/${feedbackKey}?t=${new Date().getTime()}`);
            const feedbackContent = feedbackResponse.data;

            if (isValidJSON(feedbackContent)) {
                const contentState = convertFromRaw(JSON.parse(feedbackContent));
                setEditorState(EditorState.createWithContent(contentState));
            }

            alert('Feedback saved successfully!');
        } catch (error) {
            console.error('Error saving feedback:', error);
            alert('Failed to save feedback.');
        }
    };

    if (loading) {
        return <div className=" font-mono text-[3rem] text-whiter">Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <>
        <Navbar title='Feedback Editor'  />
        <div className=' flex w-full h-full justify-center items-center'>
        <div className="feedback-editor-container mx-5 w-[95%] md:w-[90%]">
            <div className="editor-toolbar space-x-3 mx-2">
                <button
                    className={`toolbar-btn ${editorState.getCurrentInlineStyle().has('BOLD') ? 'active' : ''}`}
                    onMouseDown={(e) => { e.preventDefault(); toggleInlineStyle('BOLD'); }}
                >
                    <FaBold />
                </button>
                <button
                    className={`toolbar-btn ${editorState.getCurrentInlineStyle().has('ITALIC') ? 'active' : ''}`}
                    onMouseDown={(e) => { e.preventDefault(); toggleInlineStyle('ITALIC'); }}
                >
                    <FaItalic />
                </button>
                <button
                    className={`toolbar-btn ${editorState.getCurrentInlineStyle().has('UNDERLINE') ? 'active' : ''}`}
                    onMouseDown={(e) => { e.preventDefault(); toggleInlineStyle('UNDERLINE'); }}
                >
                    <FaUnderline />
                </button>
                <button
                    className={`toolbar-btn ${RichUtils.getCurrentBlockType(editorState) === 'unordered-list-item' ? 'active' : ''}`}
                    onMouseDown={(e) => { e.preventDefault(); toggleBlockType('unordered-list-item'); }}
                >
                    <FaListUl />
                </button>
                <button
                    className={`toolbar-btn ${RichUtils.getCurrentBlockType(editorState) === 'ordered-list-item' ? 'active' : ''}`}
                    onMouseDown={(e) => { e.preventDefault(); toggleBlockType('ordered-list-item'); }}
                >
                    <FaListOl />
                </button>
                <button
                    className="toolbar-btn"
                    onMouseDown={(e) => { e.preventDefault(); setEditorState(EditorState.undo(editorState)); }}
                >
                    <FaUndo />
                </button>
                <button
                    className="toolbar-btn"
                    onMouseDown={(e) => { e.preventDefault(); setEditorState(EditorState.redo(editorState)); }}
                >
                    <FaRedo />
                </button>
            </div>

            <div className="editor-wrapper">
                <Editor
                    editorState={editorState}
                    onChange={setEditorState}
                    handleKeyCommand={handleKeyCommand}
                    placeholder="Enter feedback here..."
                    spellCheck={true}
                />
            </div>

            <div className="editor-footer">
                <button className="save-btn" onClick={handleSaveClick}>
                    Save Feedback
                </button>
                <button className="save-btn" style={{backgroundColor: 'red'}} onClick={()=>{navigate('/teacher-dashboard')}}>
                    Close
                </button>
            </div>
        </div>
        </div>
        </>
    );
};

export default FeedbackEditor;
