import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './StudentDashboard.module.css';
import Navbar from './Navbar';
import { Oval } from 'react-loader-spinner';
import TooltipButton from './ToolTip';
import BackAnchor from './BackAnchor';
import {jwtDecode} from 'jwt-decode';  
import Cookies from 'js-cookie';  
import { useNavigate } from 'react-router-dom';

// Function to extract student details from the idToken
const getStudentDetails = () => {
    const token = Cookies.get('idToken');  // Use Cookies instead of localStorage
    if (token) {
        try {
            const decodedToken = jwtDecode(token);
            return {
                studentId: decodedToken['sub'],  // Assuming 'sub' contains the student ID
                studentName: decodedToken['name'],  // Use 'name' as per the new pool
            };
        } catch (error) {
            console.error('Error decoding token:', error);
            return { studentId: null, studentName: '' };
        }
    }
    return { studentId: null, studentName: '' };
};

const StudentDashboard = () => {
    const [classes, setClasses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [classAssignments, setClassAssignments] = useState([]); // Hold assignments from all classes
    const navigate = useNavigate();

    // Get the student details (ID and name)
    const { studentId, studentName } = getStudentDetails();

    const toolText = 'You can view your assignments & their grades on this page.';

    // Fetch classes and assignments for all the student's classes
    useEffect(() => {
        if (studentId) {
            fetchClassesAndAssignments(studentId);
        }
    }, [studentId]);

    // Fetch classes and their assignments for the logged-in student
    const fetchClassesAndAssignments = async (studentId) => {
        setLoading(true);
        try {
            // Fetch classes
            const classResponse = await axios.get(`https://weip5c9b6a.execute-api.eu-north-1.amazonaws.com/FetchClassStudent?studentID=${studentId}`);
            const fetchedClasses = classResponse.data.classes || [];
            setClasses(fetchedClasses);

            // Fetch assignments for each class
            const allAssignments = [];
            for (const cls of fetchedClasses) {
                const submissionResponse = await axios.get(`https://04adzvvhgc.execute-api.eu-north-1.amazonaws.com/fetchSubmissions?classId=${cls.ClassID}`);
                const assignments = submissionResponse.data.map(sub => ({
                    id: sub.SubmissionID,
                    classId: cls.ClassID, // Include the class ID with each assignment
                    name: sub.studentName || studentName,  // Use studentName from token
                    assignmentName: sub.assignmentName,  // Now using the assignmentName fetched from the backend
                    submissionDate: sub.submissionDate,
                    grade: sub.grade,
                    submissionKey: sub.submissionKey,
                    feedbackKey: sub.feedbackKey,
                    feedbackVisible: sub.feedbackVisible
                }));
                allAssignments.push(...assignments);
            }

            // Set all assignments to the state
            setClassAssignments(allAssignments);
            setLoading(false);
        } catch (error) {
            console.error('Failed to fetch classes or assignments', error);
            setLoading(false);
        }
    };

    // Handle viewing feedback for a submission
    const handleViewFeedbackClick = (submissionID, feedbackVisible) => {
        if (!feedbackVisible) {
            alert('Feedback is not available for this submission.');
            return;
        }

        // Navigate to StudentFeedbackViewer when feedback is clicked
        navigate(`/view-feedback/${submissionID}`);
    };

    return (
        <>
            <Navbar title='Dashboard' logout={true} authenticated={studentId} />
            {studentId ?
            <div className='flex justify-center items-center'>
                <div className='bg-blue-100 w-[95%] md:w-[90%] pb-10 pt-10 px-20 h-auto rounded-lg'>
                    <div>
                        <h1 className='text-black font-sans text-[26px]'>Welcome, {studentName}</h1>  {/* Display student's name */}
                    </div>
                    <div className={styles.assignmentTable}>
                        <h3>Your Assignments</h3>
                        {loading ? (
                            <div className='flex justify-center mt-4'>
                                <Oval height={40} width={40} color="#0b3050" visible={true} />
                            </div>
                        ) : classAssignments.length > 0 ? (
                            <table>
                                <thead>
                                    <tr>
                                        <th>Class ID</th>
                                        <th>Assignment Name</th>
                                        <th>Submission Date</th>
                                        <th>Grade</th>
                                        <th>Feedback</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {classAssignments.map((assignment, index) => (
                                        <tr key={index}>
                                            <td>{assignment.classId}</td>  {/* Display class ID */}
                                            <td>{assignment.assignmentName}</td> {/* Use the correct assignment name */}
                                            <td>{assignment.submissionDate
                                                ? new Date(assignment.submissionDate).toLocaleString('en-US', {
                                                    year: 'numeric',
                                                    month: 'long',
                                                    day: 'numeric',
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                    hour12: true,
                                                })
                                                : 'No Date Available'}</td>
                                            <td>{assignment.grade || 'Not graded yet'}</td>
                                            <td>
                                                {assignment.feedbackVisible ? (
                                                    <button onClick={() => handleViewFeedbackClick(assignment.id, assignment.feedbackVisible)}>View Feedback</button>
                                                ) : (
                                                    'Feedback not available'
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        ) : (
                            <p>No assignments available.</p>
                        )}
                    </div>

                    <BackAnchor text='Go to student upload page' path='/student-upload' />
                    <TooltipButton text='Hover for help!' tooltip={toolText} />
                </div>
            </div>
            :
            (<div className='w-full h-[80vh] justify-center items-center text-red-500 font-bold flex'>
                <p className='text-[2.4rem]'>&#9888; User is not authenticated. Please log in.</p>
            </div>)
            }
        </>
    );
};

export default StudentDashboard;
