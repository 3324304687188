import React, { useState, useEffect } from 'react';
import styles from './ClassTable.module.css';

const ClassTable = ({ selectedClassId, classDetails, handleEditClick, handleShowToStudentClick, saveGrade }) => {
    const [grades, setGrades] = useState({});
    const [editingId, setEditingId] = useState(null);
    const [showToStudentStatus, setShowToStudentStatus] = useState({});  // Holds the button states

    // This effect will run whenever the classDetails changes to populate the initial showToStudentStatus
    useEffect(() => {
        if (classDetails && classDetails.students) {
            const initialStatuses = {};
            classDetails.students.forEach(student => {
                initialStatuses[student.id] = student.feedbackVisible;  // Use the actual backend value
            });
            setShowToStudentStatus(initialStatuses);
        }
    }, [classDetails]);

    if (!selectedClassId) {
        return <p className={styles.message}>Please select a class to view details.</p>;
    }

    if (!classDetails) {
        return <p className={styles.message}>Loading class details...</p>;
    }

    if (!classDetails.students || classDetails.students.length === 0) {
        return <p className={styles.message}>No students found for this class.</p>;
    }

    // Handle grade change for each student
    const handleGradeChange = (e, studentId) => {
        const newGrade = e.target.value;
        setGrades(prevGrades => ({
            ...prevGrades,
            [studentId]: newGrade,
        }));
    };

    return (
        <div className={[styles.classTable]}>
            <h3 className={styles.title}>Class Details</h3>
            <div className=' w-full flex justify-center items-center'>
    <table className={styles.table}>
        <thead>
            <tr>
                <th>Student Name</th>
                <th>Assignment Name</th>
                <th>Submission Date</th>
                <th>Grade</th>
                <th>Edit Feedback</th>
                <th style={{minWidth: '5rem'}}>Show to Student</th>
            </tr>
        </thead>
        <tbody>
            {classDetails.students.map(student => (
                <tr key={student.id}>
                    <td>{student.name}</td>
                    <td>{student.assignmentName}</td>
                    {/* Format the submission date */}
                    <td>
                        {student.submissionDate 
                            ? new Date(student.submissionDate).toLocaleString('en-US', {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                                hour: '2-digit',
                                minute: '2-digit',
                                hour12: true,
                            })
                            : 'No Date Available'}
                    </td>

                    {/* Grade */}
                    <td>
                        {editingId === student.id ? (
                            <>
                                <input
                                    type="number"
                                    value={grades[student.id] || student.grade || ''}
                                    onChange={(e) => handleGradeChange(e, student.id)}
                                    placeholder="Enter grade"
                                />
                                <div className=' flex space-x-1 my-1'>
                                <button className=' rounded bg-green-500 px-4 py-1  text-white' onClick={() => {
                                    saveGrade(student.id, grades[student.id] || student.grade);
                                    setEditingId(null);
                                }}>
                                    Save Grade
                                </button>
                                <button onClick={()=>{setEditingId(null);
                                }} className=' text-white bg-red-500 text-center rounded px-2 py-1'>
                                    X
                                </button>
                                </div>
                            </>
                        ) : (
                            <span className=' text-blue-500 hover:cursor-pointer hover:underline' onClick={() => setEditingId(student.id)}>
                                {student.grade || 'Not graded yet'}
                            </span>
                        )}
                    </td>
                    {/* Edit feedback button */}
                    <td>
                                <button
                                    className={styles.editButton}
                                    onClick={() => handleEditClick(student.id)}
                                >
                                    Edit
                                </button>
                            </td>

                            {/* Show feedback to student button */}
                            <td>
                                <button
                                    className={`${styles.showToStudentButton} rounded px-2 py-1 text-white ${showToStudentStatus[student.id] ? 'bg-green-500' : 'bg-blue-500'}`}
                                    onClick={() => {
                                        if (!showToStudentStatus[student.id]) {  // Only allow if feedback is not visible
                                            handleShowToStudentClick(student.id);
                                            setShowToStudentStatus(prevStatus => ({
                                                ...prevStatus,
                                                [student.id]: true,  // Set the button state to true (ON)
                                            }));
                                        }
                                    }}
                                    disabled={showToStudentStatus[student.id]}  // Disable button if feedback is already visible
                                >
                                   {showToStudentStatus[student.id] ? 'ON' : 'OFF'}
                                </button>
                            </td>

                </tr>
            ))}
        </tbody>
    </table>
    </div>
        </div>
    );
};

export default ClassTable;
