import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import TeacherUploadForm from './TeacherUploadForm';
import AuthCallback from './AuthCallback';
import StudentSubmissionForm from './StudentSubmissionForm';
import StudentDashboard from './StudentDashboard';
import Footer from './Footer';
import HomePage from './HomePage';
import DashboardEN from './dashboardEN';
import TeacherOrStudent from './TorS';
import TeacherDashboard from './TeacherDashboard';
import Navbar from './Navbar';
import StudentFeedbackViewer from './StudentFeedbackViewer';
import FeedbackEditor from './FeedbackEditor';
import { getCookie, removeCookie } from './cookieHelper'; 

function App() {
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    const idToken = getCookie('idToken');
    setAuthenticated(!!idToken); // Update authenticated based on the presence of idToken
  }, []);

  // Protected Route component checks for token and redirects if unauthenticated
  const ProtectedRoute = ({ element }) => {
    const idToken = getCookie('idToken');  // Check token in each route
    return idToken ? element : <Navigate to="/teacher-or-student" />;
  };

  const handleLogout = () => {
    removeCookie('idToken');  // Remove the token when logging out
    setAuthenticated(false);  // Set authenticated state to false
  };

  return (
    <Router>
      <Navbar title="EasyGrade" logout={true} authenticated={authenticated} onLogout={handleLogout} />
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<DashboardEN />} />
        <Route path="/Home" element={<HomePage />} />
        <Route path="/teacher-or-student" element={<TeacherOrStudent />} />
        <Route path="/auth-callback" element={<AuthCallback setAuthenticated={setAuthenticated} />} /> 
        
        {/* Protected Routes */}
        <Route path="/student-upload" element={<ProtectedRoute element={<StudentSubmissionForm />} />} />
        <Route path="/teacher-upload" element={<ProtectedRoute element={<TeacherUploadForm />} />} />
        <Route path="/teacher-dashboard" element={<ProtectedRoute element={<TeacherDashboard />} />} />
        <Route path="/student-dashboard" element={<ProtectedRoute element={<StudentDashboard />} />} />
        <Route path="/edit-feedback/:submissionID" element={<ProtectedRoute element={<FeedbackEditor />} />} />
        <Route path="/view-feedback/:submissionID" element={<ProtectedRoute element={<StudentFeedbackViewer />} />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
