import React, { useState, useEffect } from 'react';
import './App.css';
import axios from 'axios';
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import Navbar from './Navbar';
import TooltipButton from './ToolTip';
import BackAnchor from './BackAnchor';
import {jwtDecode} from 'jwt-decode';  
import Cookies from 'js-cookie'; 

export default function StudentSubmissionForm() {
    document.body.style.backgroundColor = '#0b3050';
    
    const [studentId, setStudentId] = useState('');
    const [studentName, setStudentName] = useState('');
    const [classId, setClassId] = useState('');
    const [submissionKey, setSubmissionKey] = useState(''); 
    const [message, setMessage] = useState('');
    const [uploading, setUploading] = useState(false);
    const [good, setGood] = useState(false);

    // Extract student ID and name from the JWT token and set them as read-only fields
    useEffect(() => {
        const token = Cookies.get('idToken'); // Get the token from cookies instead of localStorage
        if (token) {
            try {
                const decodedToken = jwtDecode(token);

                const extractedStudentId = decodedToken['sub'];
                const extractedStudentName = decodedToken['name'];  // Use 'name' attribute

                setStudentId(extractedStudentId); 
                setStudentName(extractedStudentName); 
            } catch (error) {
                setMessage('Error decoding token. Please log in again.');
            }
        } else {
            setMessage('User is not authenticated. Please log in.');
        }

        // Get the submissionKey from URL query parameter if provided
        const urlParams = new URLSearchParams(window.location.search);
        const prefilledSubmissionKey = urlParams.get('submissionKey');
        if (prefilledSubmissionKey) {
            setSubmissionKey(prefilledSubmissionKey);
        }
    }, []);

    const handleClassIdChange = (e) => setClassId(e.target.value);
    const handleSubmissionKeyChange = (e) => setSubmissionKey(e.target.value);

    const handleChangeStatus = ({ meta, file }, status) => {
        console.log(status, meta);
        if (status === 'done') {
            console.log('File:', file);
        }
    };

    const handleSubmit = async (files) => {
        const file = files[0].file;
        if (!file.name.endsWith('.docx')) {
            setMessage('Please upload a .docx file.');
            return;
        }

        setUploading(true);

        try {
            const fileContent = await file.arrayBuffer();
            const fileBase64 = btoa(
                new Uint8Array(fileContent)
                    .reduce((data, byte) => data + String.fromCharCode(byte), '')
            );

            // POST request: submit student work
            const response = await axios.post('https://4qqhiz9s05.execute-api.eu-north-1.amazonaws.com/SubmitStudentWork', {
                studentId: studentId,
                studentName: studentName,
                classId: classId,
                submissionKey: submissionKey,  // Ensure submissionKey (rubric key) is sent correctly
                submissionFile: {
                    name: file.name,
                    content: fileBase64
                }
            });

            setGood(true);
            alert(`File ${file.name} has been uploaded successfully.`);

        } catch (error) {
            console.error("Error uploading submission:", error);
            setGood(false);
            setMessage('Failed to upload submission. Please try again.');
        }

        setUploading(false);
    };

    return (
        <>
            <Navbar title={'Upload'} logout={true} authenticated={studentId}/>
            {studentId ? (
            <div className='flex justify-center items-center h-screen' style={{ backgroundColor: "#0b3050" }}>
            <div id='whole' className={`bg-blue-100 w-[80%] md:w-[90%] pb-10 pt-10 px-10 sm:px-20 h-auto rounded-lg -mt-[5rem] sm:-mt-[10rem]`} style={{width: '90%'}}>    
                <BackAnchor text={'Go to dashboard'} path={'/student-dashboard'}/>            
                {message && <p className={`text-${good ? 'green' : 'red'}-500 text-center mt-4`}>{message}</p>}
                <ul className='mt-6 text-center text-[26px] sm:text-[32px]'>
                    <li>Submit your assignment here.</li>
                </ul>

                {/* Autofilled student ID and name fields, read-only */}
                <div className="mt-6 w-full flex justify-center items-center">
                    <input
                        id="studentId"
                        type="text"
                        value={studentId}
                        readOnly
                        className="shadow appearance-none border rounded w-full sm:w-[80%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-200"
                    />
                </div>
                <div className="mt-4 w-full flex justify-center items-center">
                    <input
                        id="studentName"
                        type="text"
                        value={studentName}
                        readOnly
                        className="shadow appearance-none border rounded w-full sm:w-[80%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-200"
                    />
                </div>

                {/* Class ID and submissionKey fields */}
                <div className="mt-4 w-full flex justify-center items-center">
                    <input
                        id="classId"
                        type="text"
                        value={classId}
                        onChange={handleClassIdChange}
                        placeholder='Class ID'
                        className="shadow appearance-none border rounded w-full sm:w-[80%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                </div>
                <div className="mt-4 w-full flex justify-center items-center">
                    <input
                        id="submissionKey"
                        type="text"
                        placeholder='Submission Key'
                        value={submissionKey}
                        onChange={handleSubmissionKeyChange}
                        className="shadow appearance-none border rounded w-full sm:w-[80%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                </div>

                {/* File upload area */}
                <Dropzone
                    className='text-black'
                    onChangeStatus={handleChangeStatus}
                    onSubmit={handleSubmit}
                    maxFiles={1}
                    multiple={false}
                    canCancel={false}
                    inputContent="Drag a file here or browse for a file to upload"
                    accept=".docx"
                    styles={{
                        dropzone: {width: '100%', height: 200, borderStyle: 'dashed', borderWidth: '2px', borderColor: 'gray', marginTop: '2rem', color: 'black'},
                        dropzoneActive: { borderColor: 'green' }, 
                    }}
                />
                
                <div className='flex items-center w-full justify-center sm:justify-normal'>
                    <TooltipButton text={'Hover for help!'} tooltip={"Submit your assignment by entering the teacher's submission key and class ID. Ensure the file is in .docx format."}/>
                </div>
            </div></div>) : (
            <div className='w-full h-[80vh] justify-center items-center text-red-500 font-bold flex'>
                <p className='text-[2.4rem]'>&#9888; User is not authenticated. Please log in.</p>
            </div>
            )}
        </>
    );
}
