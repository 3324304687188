import React, { useState, useEffect } from 'react';
import './App.css';
import axios from 'axios';
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import {jwtDecode} from "jwt-decode";
import QRCode from 'qrcode.react';
import Modal from 'react-modal';
import { Oval } from 'react-loader-spinner';
import CopyButton from './CopyButton';
import './Modal.css';
import Navbar from './Navbar';
import BackAnchor from './BackAnchor';
import TooltipButton from './ToolTip';
import Cookies from 'js-cookie'; 

Modal.setAppElement('#root');

export default function TeacherUploadForm() {
    document.body.style.backgroundColor = '#0b3050';
    
    const [assignmentName, setAssignmentName] = useState('');
    const [classId, setClassId] = useState('');
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('');
    const [uploading, setUploading] = useState(false);
    const [teacherId, setTeacherId] = useState('');
    const [rubricKey, setRubricKey] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [authErr, setAuthErr] = useState(true);

    function AuthError() {
        return <p>&#9888; User is not authenticated. Please log in.</p>;
    }

    useEffect(() => {
        const token = Cookies.get('idToken');  // Get the token from cookies instead of localStorage
        if (token) {
            try {
                const decodedToken = jwtDecode(token);
                setTeacherId(decodedToken.sub);  // Extract the teacher ID from the token
                setAuthErr(true);
            } catch (error) {
                setMessage('Error decoding token. Please log in again.');
                setAuthErr(false);
            }
        } else {
            setMessage(<AuthError />);
            setAuthErr(false);
        }
    }, []);

    const handleAssignmentNameChange = (e) => setAssignmentName(e.target.value);
    const handleClassIdChange = (e) => setClassId(e.target.value);

    const handleChangeStatus = ({ meta, file }, status) => {
        if (status === 'done') {
            console.log('File:', file);
        }
    };

    const handleSubmit = async (files) => {
        if (!teacherId) {
            setMessage('Teacher ID is missing. Please log in again.');
            return;
        }
        if (!classId) {
            setMessage('Class ID is required.');
            return;
        }
        if (!assignmentName) {
            setMessage('Assignment name is required.');
            return;
        }

        const file = files[0].file;
        if (!file.name.endsWith('.docx')) {
            setMessage('Please upload a .docx file.');
            return;
        }

        setUploading(true);

        try {
            const fileBuffer = await file.arrayBuffer(); // Get the file as an ArrayBuffer

            // Convert ArrayBuffer to Base64 (required to send binary data over JSON)
            const base64File = btoa(
                new Uint8Array(fileBuffer)
                    .reduce((data, byte) => data + String.fromCharCode(byte), '')
            );

            const uploadResponse = await axios.post('https://ngtzgqveo4.execute-api.eu-north-1.amazonaws.com/UploadRubric', {
                teacherId: teacherId,
                classId: classId,
                assignmentName: assignmentName,
                rubricFile: {
                    name: file.name,
                    content: base64File // Send the file content as base64-encoded binary data
                }
            });

            const rubricKey = uploadResponse.data.submissionKey;

            setRubricKey(rubricKey);
            setMessageType('success');
            setMessage('Rubric uploaded successfully. Share this key with your students.');
            setModalIsOpen(true);
        } catch (error) {
            if (error.response) {
                setMessage(`Failed to upload rubric. Server responded with status: ${error.response.status}`);
            } else if (error.request) {
                setMessage('Failed to upload rubric. No response from server.');
            } else {
                setMessage(`Failed to upload rubric. Error: ${error.message}`);
            }
        }

        setUploading(false);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setMessage('');
    };

    let toolText = 'Create your class on the teacher dashboard page first. Then, return here with the class ID, insert your rubric, and name your assignment.';

    return (
        <>
            <Navbar title={'Upload'} logout={true} authenticated={teacherId}/>
            {teacherId ? 
            <div className='flex justify-center items-center h-screen' style={{ backgroundColor: "#0b3050" }}>
            <div className={`bg-blue-100 w-[95%] md:w-[90%] pb-10 pt-10 px-10 sm:px-20 h-[80%] rounded-lg -mt-[5rem] sm:-mt-[10rem]`}>
            <BackAnchor text={'Go to dashboard'} path={'/teacher-dashboard'} />
            
            {message && <p className={authErr ? `text-${messageType === 'success' ? 'green' : 'red'}-500 mt-4 font-Roboto ` : 'text-red-500 text-[2.5rem] font-Roboto h-[90%] text-center flex justify-center items-center mt-4 '}>{message}</p>}
            
            {uploading && (
                <div className="flex justify-center mt-4">
                    <Oval
                        height={40}
                        width={40}
                        color="#4fa94d"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="#4fa94d"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                    />
                </div>
            )}
            {authErr && <><ul className='mt-6 text-center'>
                <li className='text-[26px] sm:text-[32px]'>Submit your files here.</li>
            </ul>
            
            <div className="mt-6 justify-center items-center flex">
                <input
                    id="assignmentName"
                    type="text"
                    value={assignmentName}
                    onChange={handleAssignmentNameChange}
                    placeholder='Assignment name'
                    className="shadow appearance-none border rounded w-full sm:w-[80%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
            </div>
            
            <div className="mt-4 justify-center items-center flex">
                <input
                    id="classId"
                    type="text"
                    value={classId}
                    placeholder='Class ID'
                    onChange={handleClassIdChange}
                    className="shadow appearance-none border rounded w-full sm:w-[80%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
            </div>
            
            <Dropzone
                className='text-black'
                onChangeStatus={handleChangeStatus}
                onSubmit={handleSubmit}
                maxFiles={1}
                multiple={false}
                canCancel={false}
                inputContent="Drag a file here or browse for a file to upload"
                accept=".docx"
                styles={{
                    dropzone: { width: '100%', height: 200, borderStyle: 'dashed', borderWidth: '2px', borderColor: 'gray', marginTop: '2rem', color: 'black' },
                    dropzoneActive: { borderColor: 'green' },
                }}
            />
            
            <div className='flex items-center w-full justify-center sm:justify-normal'>
                <TooltipButton text={'Hover for help!'} tooltip={toolText} />
            </div></>}
            
        </div>
        
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel="Rubric Key"
            className="modal"
            overlayClassName="overlay"
        >
            <div className="modal-content">
                <h2 className="text-center text-xl font-bold">Share this key with your students</h2>
                <p className="text-center text-lg">{rubricKey}</p>
                <div className="flex justify-center mt-4">
                    <QRCode value={`https://easygrade.me/student-upload?key=${rubricKey}`} size={128} />
                </div>
                <div className="flex justify-center mt-4">
                    <CopyButton textToCopy={rubricKey} />
                </div>
                <div className="flex justify-center mt-4">
                    <button onClick={closeModal} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                        Close
                    </button>
                </div>
            </div>
        </Modal>
         </div>
            : <div className=' w-full h-[80vh] justify-center items-center text-red-500 font-bold flex'>
            <p className=' text-[2.4rem]'>&#9888; User is not authenticated. Please log in.</p>
            </div>
        }
            </>
       
    );
}
