import React from 'react';
import Navbar from './Navbar';

// OAuth utility to handle constructing URLs for Teacher and Student logins
const constructOAuthUrl = (baseUrl, clientId, scope, redirectUri) => {
    const responseType = "response_type=code";
    return `${baseUrl}?client_id=${clientId}&${responseType}&${scope}&redirect_uri=${redirectUri}`;
};

export default function TeacherOrStudent() {
    // Set body styles dynamically
    React.useEffect(() => {
        document.body.style.backgroundColor = '#0b3050';
        document.body.style.display = 'flex';
        document.body.style.paddingTop = '0';

        return () => {
            // Clean up body styles when component is unmounted
            document.body.style = '';
        };
    }, []);

    // Cognito domains for Teacher and Student login
    const baseLoginUrlTeacher = "https://i5.auth.eu-north-1.amazoncognito.com/oauth2/authorize";
    const baseLoginUrlStudent = "https://easygrade.auth.eu-north-1.amazoncognito.com/oauth2/authorize";

    // Client IDs for Teacher and Student
    const teacherClientId = "7pic17ep56u5p9fkdhkjavte81";
    const studentClientId = "6eel745ag1uqd7aajvaeic3ueg";

    // Scopes for Teacher and Student
    const scopeTeacher = "scope=email+openid";
    const scopeStudent = "scope=email+openid+profile";

    // Redirect URIs with proper encoding for Teacher and Student
    const redirectUriTeacher = encodeURIComponent('https://easygrade.me/auth-callback?role=teacher');
    const redirectUriStudent = encodeURIComponent('https://easygrade.me/auth-callback?role=student');

    // Construct login URLs for OAuth flow
    const loginUrlTeacher = constructOAuthUrl(baseLoginUrlTeacher, teacherClientId, scopeTeacher, redirectUriTeacher);
    const loginUrlStudent = constructOAuthUrl(baseLoginUrlStudent, studentClientId, scopeStudent, redirectUriStudent);

    // Event handlers for login buttons
    const handleTeacherClick = () => {
        window.location.href = loginUrlTeacher;
    };

    const handleStudentClick = () => {
        window.location.href = loginUrlStudent;
    };

    return (
        <>
            <Navbar title={""} logout={false} />
            <div className='grid py-[100px] mt-[3vw]'>
                <div className='flex items-center justify-center text-white font-Tajawal mb-10 text-[30px] md:text-[60px]'>
                    Which one represents you?
                </div>
                <div className='flex w-screen justify-center items-center'>
                    <button
                        onClick={handleTeacherClick}
                        className='bg-white justify-center h-[250px] w-[250px] hover:scale-105 rounded grid mr-[7vw] sm:mr-[15vw] ml-3 sm:ml-0 text-[40px] sm:text-[50px] font-Tajawal transition ease-in-out delay-50 duration-300'>
                        <img src={require('./Teacher.png')} className='w-[130px] ml-4 mt-9 -mb-9' alt="Teacher" />
                        <span>Teacher</span>
                    </button>
                    <button
                        onClick={handleStudentClick}
                        className='bg-white justify-center h-[250px] w-[250px] rounded grid text-[40px] sm:text-[50px] mr-3 sm:mr-0 font-Tajawal hover:scale-105 transition ease-in-out delay-50 duration-300'>
                        <img src={require('./Student.png')} className='w-[150px] pt-4 pl-5 mt-6 -mb-6' alt="Student" />
                        <span>Student</span>
                    </button>
                </div>
            </div>
        </>
    );
}
