import React, { useEffect, useState } from 'react';
import { Editor, EditorState, convertFromRaw, ContentState } from 'draft-js';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './FeedbackEditor.css'; 
import Navbar from './Navbar';

const StudentFeedbackViewer = () => {
    const { submissionID } = useParams();  // Get submissionID from the URL
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Fetch feedback content from S3 when the component is loaded
    useEffect(() => {
        const fetchFeedback = async () => {
            try {
                // Fetch the feedback key associated with the submission
                const response = await axios.get(`https://jkcme8yg4d.execute-api.eu-north-1.amazonaws.com/FetchFeedback?submissionID=${submissionID}`);
                const feedbackKey = response.data.feedbackKey;

                // Fetch the feedback content from S3 using the feedback key
                const feedbackResponse = await axios.get(`https://s3-eu-north-1.amazonaws.com/feedbacks-bucket/${feedbackKey}`);
                const feedbackContent = feedbackResponse.data;

                // Try to parse the content as JSON (Draft.js format), fallback to plain text
                try {
                    const contentState = convertFromRaw(JSON.parse(feedbackContent));
                    setEditorState(EditorState.createWithContent(contentState));
                } catch (parseError) {
                    const plainTextContent = ContentState.createFromText(feedbackContent);
                    setEditorState(EditorState.createWithContent(plainTextContent));
                }
            } catch (error) {
                setError('Failed to load feedback.');
                console.error('Error fetching feedback:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchFeedback();
    }, [submissionID]);

    if (loading) {
        return <div className=" font-mono text-[3rem] text-whiter">Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <>
        <Navbar title='View Feedback'  />
        <div className='flex w-full h-full justify-center items-center'>
            <div className="feedback-editor-container mx-5 w-[95%] md:w-[90%]">
                <div className="editor-wrapper">
                    <Editor
                        editorState={editorState}
                        readOnly={true}  // Ensure read-only mode for students
                        placeholder="No feedback available."
                        spellCheck={true}
                    />
                </div>
            </div>
        </div>
        </>
    );
};

export default StudentFeedbackViewer;
