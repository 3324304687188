import React from 'react';
import logo from './easygradepfp.png';
import './App.css';
import { useNavigate } from 'react-router-dom';
import { removeCookie } from './cookieHelper'; // Import the cookie removal function

export default function Navbar({ title, logout, authenticated }) {
    const navigate = useNavigate();

    const handleLogout = () => {
        // Remove tokens and any other session-related cookies
        removeCookie('idToken');
        removeCookie('accessToken');
        removeCookie('userName');
        
        navigate('/');
    };

    const handleLogin = () => {
        navigate('/teacher-or-student');
    };

    return (
        <nav className="container2">
            <p className="inner-container-extras" id="topText">{title}</p>
            <div className="logoContainer" onClick={() => navigate("/")}>
                <img className="logo w-12 md:10 sm:9" src={logo} style={{ marginTop: "0vh" }} alt="Logo" />
            </div>
            {logout && (authenticated ? (
                <button
                    onClick={handleLogout}
                    className='bg-blue-100 hover:bg-red-500 active:bg-slate-900 ease-in-out duration-200 hover:text-white rounded w-[85px] sm:w-[130px] h-[45px] sm:h-[50px] fixed right-4 top-4'>
                    Log out
                </button>
            ) : (
                <button
                    onClick={handleLogin}
                    className='bg-blue-100 hover:bg-blue-500 active:bg-slate-900 ease-in-out duration-200 hover:text-white rounded w-[85px] sm:w-[130px] h-[45px] sm:h-[50px] fixed right-4 top-4'>
                    Log in
                </button>
            ))}
        </nav>
    );
}
