import React from 'react';

const TooltipButton = ({ text, tooltip }) => {
  return (
    <div className="relative inline-block group mt-8">
      <button className="bg-yellow-500 text-white border-none py-2 px-4 rounded-md text-lg cursor-pointer transition-transform duration-200 ease-out transform group-hover:scale-105">
        {text}
      </button>
      <span className="invisible w-[300px] opacity-0 group-hover:visible group-hover:opacity-100 bg-gray-800 text-white text-center rounded-md py-2 absolute z-10 bottom-full left-1/2 transform -translate-x-1/2 mb-2 transition-opacity duration-300">
        {tooltip}
        <span className="absolute top-full left-1/2 transform -translate-x-1/2 w-0 h-0 border-t-8 border-t-gray-800 border-l-transparent border-l-8 border-r-transparent border-r-8"></span>
      </span>
    </div>
  );
};

export default TooltipButton;
