import './App.css';

export default function Footer(){
    return (
        <>  
        <footer className='w-auto'>
        © 2024 Easy Grade
        </footer>
        </>
    )
}