import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import styles from './TeacherDashboard.module.css';
import Modal from './Modal';
import Navbar from './Navbar';
import { Oval } from 'react-loader-spinner';
import TooltipButton from './ToolTip';
import BackAnchor from './BackAnchor';
import ClassTable from './ClassTable';
import {jwtDecode} from 'jwt-decode';  
import Cookies from 'js-cookie';   
import { useNavigate } from 'react-router-dom';

const getTeacherId = () => {
    const token = Cookies.get('idToken'); // Use cookies instead of localStorage
    if (token) {
        try {
            const decodedToken = jwtDecode(token);
            return decodedToken['sub']; // Extract teacher ID from the token
        } catch (error) {
            console.error('Error decoding token:', error);
            return null;
        }
    }
    return null;
};

const TeacherDashboard = () => {
    const [classes, setClasses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedClassId, setSelectedClassId] = useState(null);
    const [classDetails, setClassDetails] = useState(null);
    const [showCreateClassModal, setShowCreateClassModal] = useState(false);
    const [newClassName, setNewClassName] = useState('');
    const [notification, setNotification] = useState('');
    const [tID, setTID] = useState();
    const navigate = useNavigate();
    const pollingIntervalRef = useRef(null);

    const toolText = 'You can create and view classes here, see enrolled students and their grades, and click "edit" to correct any AI errors.';

    function AuthError() {
        return (
            <div className=' w-full h-full justify-center items-center text-red-500 font-bold flex'>
                <p>&#9888; User is not authenticated. Please log in.</p>
            </div>
        );
    }

    useEffect(() => {
        const teacherId = getTeacherId();
        setTID(teacherId);
        if (!teacherId) {
            setLoading(false);
            return;
        }
        fetchClasses();

        pollingIntervalRef.current = setInterval(() => {
            if (selectedClassId) {
                fetchSubmissions(selectedClassId);
            } else {
                fetchClasses();
            }
        }, 3000000); // Poll every 50 minutes

        return () => {
            clearInterval(pollingIntervalRef.current);
        };
    }, [selectedClassId]);

    const fetchClasses = async () => {
        try {
            const teacherId = getTeacherId();
            if (!teacherId) {
                console.error('No teacher ID found');
                setLoading(false);
                return;
            }
            console.log(`Fetching classes for teacher ID: ${teacherId}`);
            const response = await axios.get(`https://p87k05ebbk.execute-api.eu-north-1.amazonaws.com/fetchClasses?teacherId=${teacherId}`);
            setClasses(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Failed to fetch classes', error);
            setLoading(false);
        }
    };

    const fetchSubmissions = async (classId) => {
        try {
            console.log(`Fetching submissions for class ID: ${classId}`);
            const response = await axios.get(`https://04adzvvhgc.execute-api.eu-north-1.amazonaws.com/fetchSubmissions?classId=${classId}`);
            const submissions = response.data;
            console.log('Submissions fetched:', submissions);

            // Update the submissions list and handle assignment name
            const updatedSubmissions = submissions.map((sub) => ({
                id: sub.SubmissionID,
                name: sub.studentName,
                assignmentName: sub.assignmentName || 'Unknown Assignment',
                submissionDate: sub.submissionDate,
                grade: sub.grade,
                feedbackKey: sub.feedbackKey,
                feedbackVisible: sub.feedbackVisible === 'true' || sub.feedbackVisible === true, // Ensure boolean handling
                submissionFile: sub.submissionFile,
            }));

            setClassDetails({ students: updatedSubmissions });
        } catch (error) {
            console.error('Failed to fetch submissions', error);
            setClassDetails({ students: [] });
        }
    };

    const handleCreateClassClick = () => {
        setShowCreateClassModal(true);
    };

    const handleModalClose = () => {
        setShowCreateClassModal(false);
    };

    const handleNewClassNameChange = (e) => {
        setNewClassName(e.target.value);
    };

    const createClass = async () => {
        if (newClassName) {
            try {
                const teacherId = getTeacherId();
                const response = await axios.post('https://0r0wwsavkb.execute-api.eu-north-1.amazonaws.com/createClass', {
                    name: newClassName,
                    teacherId
                });

                if (response.status === 200 || response.status === 201) {
                    const createdClass = response.data;
                    setClasses(prevClasses => [...prevClasses, createdClass]);
                    setShowCreateClassModal(false);
                    setNewClassName('');
                    setNotification('Class created successfully!');
                    setTimeout(() => setNotification(''), 5000);
                } else {
                    setNotification('Failed to create class');
                    setTimeout(() => setNotification(''), 5000);
                }
            } catch (error) {
                setNotification('Failed to create class');
                setTimeout(() => setNotification(''), 5000);
            }
        } else {
            setNotification('Class name cannot be empty');
            setTimeout(() => setNotification(''), 5000);
        }
    };

    const handleClassClick = (classId) => {
        setSelectedClassId(classId);
        fetchSubmissions(classId);
    };

    const handleEditClick = (submissionID) => {
    const token = Cookies.get('idToken'); // Check if token exists in cookies
    if (!token) {
        // Redirect to the TorS page if the token is missing
        navigate('/teacher-or-student');
        return;
    }

    try {
        const decodedToken = jwtDecode(token);  // Decode the token to ensure it's valid
        if (!decodedToken) {
            // If decoding fails, redirect to the TorS page
            navigate('/teacher-or-student');
            return;
        }
        
        // Proceed to open the edit page in a new tab
        const newTab = window.open(`/edit-feedback/${submissionID}`, '_blank');
        if (newTab) {
            newTab.focus();
        }
    } catch (error) {
        console.error('Error decoding token:', error);
        // Redirect to the TorS page if there's an error decoding the token
        navigate('/teacher-or-student');
    }
};

    const handleShowToStudentClick = async (submissionID, feedbackVisible) => {
        try {
            if (feedbackVisible) {
                alert('Feedback is already visible.');
                return;
            }

            setClassDetails((prevState) => {
                const updatedStudents = prevState.students.map(student => {
                    if (student.id === submissionID) {
                        return {
                            ...student,
                            feedbackVisible: true  // Mark as visible
                        };
                    }
                    return student;
                });

                return {
                    ...prevState,
                    students: updatedStudents
                };
            });

            await axios.put(`https://zvq33dj5sl.execute-api.eu-north-1.amazonaws.com/showtostudentss`, { submissionID, feedbackVisible: true });

            fetchSubmissions(selectedClassId);

            alert('Feedback is now visible to the student!');
        } catch (error) {
            alert('Failed to update feedback visibility. Please try again later.');
            console.error('Error updating visibility:', error);

            fetchSubmissions(selectedClassId);
        }
    };

    const saveGrade = async (studentID, grade) => {
        try {
            setClassDetails((prevState) => {
                const updatedStudents = prevState.students.map(student => {
                    if (student.id === studentID) {
                        return {
                            ...student,
                            grade: grade  // Optimistically update the grade
                        };
                    }
                    return student;
                });

                return {
                    ...prevState,
                    students: updatedStudents
                };
            });

            await axios.put(`https://eg6ktdcvch.execute-api.eu-north-1.amazonaws.com/SaveGrades`, { studentID, grade });
            
            alert('Grade saved successfully!');
            
        } catch (error) {
            alert('Failed to save grade');
            console.error('Error saving grade:', error);

            fetchSubmissions(selectedClassId);  
        }
    };

    return (
        <>
            <Navbar title='Dashboard' logout={true} authenticated={tID}/>
            {tID ? (
                <div className='flex justify-center items-center'>
                <div className='bg-blue-100 w-[95%] md:w-[90%] pb-10 pt-10 px-20 h-auto min-h-[40rem] rounded-lg'>
                    <div className='w-full flex justify-center items-center mb-6'>
                        <p className='text-blue-500 font-Tajawal text-[1.2rem] font-bold hover:underline hover:cursor-pointer ' onClick={handleCreateClassClick}>
                            Create New Class
                        </p>
                    </div>
                    <div className={styles.content}>
                        {loading ? (
                            <div className='flex justify-center mt-4'>
                                <Oval height={40} width={40} color="#0b3050" visible={true} ariaLabel='oval-loading' secondaryColor="#007bff" strokeWidth={2} strokeWidthSecondary={2} />
                            </div>
                        ) : ( classes.length > 0 ?
                            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
                                {classes.map(cls => (
                                    <div key={cls.ID} className='p-3 m-2 hover:scale-105 ease-in-out duration-100 rounded bg-white border-black solid border hover:cursor-pointer text-black' onClick={() => handleClassClick(cls.ID)} style={{ borderRadius: '5px' }}>
                                        <p>{cls.name}</p>
                                    </div> 
                                ))}
                            </div> : <div className=' my-10 font-Tajawal text-darkBlue text-[1.5rem]'> No classes found. </div>
                        )}
                        <BackAnchor text='Go to teacher upload page' path='/teacher-upload' />
                    </div>
                    {selectedClassId && (
                        <ClassTable 
                            selectedClassId={selectedClassId} 
                            classDetails={classDetails} 
                            handleEditClick={handleEditClick} 
                            handleShowToStudentClick={handleShowToStudentClick} 
                            saveGrade={saveGrade}
                        />
                    )}
                    {notification && <div className={styles.notification}>{notification}</div>}
                    {showCreateClassModal && (
                        <Modal onClose={handleModalClose}>
                            <div>
                                <input type='text' value={newClassName} onChange={handleNewClassNameChange} placeholder='Class Name' className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' />
                                <button onClick={createClass} className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4'>Create Class</button>
                            </div>
                        </Modal>
                    )}
                    <div className=' relative bottom-0'>
                        <TooltipButton text='Hover for help!' tooltip={toolText} />
                    </div>
                </div>
            </div>)
            : 
            (<div className=' w-full h-[80vh] justify-center items-center text-red-500 font-bold flex'>
            <p className=' text-[2.4rem]'>&#9888; User is not authenticated. Please log in.</p>
            </div>
            )}
        </>
    );
};

export default TeacherDashboard;
