import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {jwtDecode} from 'jwt-decode'; 
import Cookies from 'js-cookie';
import { Oval } from 'react-loader-spinner';

export default function AuthCallback({ setAuthenticated }) { 
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false); 
    const [error, setError] = useState(null); 

    const isTokenExpired = (token) => {
        try {
            const decoded = jwtDecode(token);
            const currentTime = Date.now() / 1000;
            return decoded.exp < currentTime;  
        } catch (error) {
            console.error("Error decoding token:", error);
            return true;
        }
    };

    useEffect(() => {
        const exchangeCodeForToken = async (code, role) => {
            setLoading(true); 
            setError(null);

            try {
                // Validate the role is correct
                if (role !== 'teacher' && role !== 'student') {
                    throw new Error('Invalid role parameter');
                }

                const params = new URLSearchParams();
                params.append('grant_type', 'authorization_code');
                params.append('code', code);
                
                // Append client ID and redirect URI based on role
                if (role === 'teacher') {
                    params.append('client_id', '7pic17ep56u5p9fkdhkjavte81'); // Teacher Client ID
                    params.append('redirect_uri', 'https://easygrade.me/auth-callback?role=teacher'); // Teacher redirect URI
                } else if (role === 'student') {
                    params.append('client_id', '6eel745ag1uqd7aajvaeic3ueg'); // Student Client ID
                    params.append('redirect_uri', 'https://easygrade.me/auth-callback?role=student'); // Student redirect URI
                }

                // Set the correct Cognito URL based on role
                const cognitoUrl = role === 'teacher' 
                    ? 'https://i5.auth.eu-north-1.amazoncognito.com/oauth2/token'  // Teacher Cognito URL
                    : 'https://easygrade.auth.eu-north-1.amazoncognito.com/oauth2/token';  // Student Cognito URL

                // Request for tokens
                const response = await axios.post(cognitoUrl, params, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                });

                const { id_token, access_token } = response.data;

                // Validate that tokens are present in the response
                if (!id_token || !access_token) {
                    throw new Error('Tokens not found in the response');
                }

                // Check if tokens are expired
                if (isTokenExpired(id_token)) {
                    throw new Error('ID token has expired');
                }

                // Store tokens in secure cookies
                Cookies.set('idToken', id_token, {
                    expires: 1, // Cookie will expire in 1 day
                    secure: true, // Only send the cookie over HTTPS
                    sameSite: 'Strict', // Prevent CSRF
                });

                Cookies.set('accessToken', access_token, {
                    expires: 1, // Cookie will expire in 1 day
                    secure: true,
                    sameSite: 'Strict',
                });

                // Decode the token and retrieve user information
                let decodedToken;
                try {
                    decodedToken = jwtDecode(id_token);
                } catch (error) {
                    throw new Error('Invalid JWT token');
                }

                // For students, extract the `name`; for teachers, extract the `email`
                const userName = role === 'student' ? decodedToken['name'] : decodedToken['email'];
                if (!userName) {
                    throw new Error('Failed to extract user information from token');
                }

                // Store user information in cookies
                Cookies.set('userName', userName, {
                    expires: 1, // Expire in 1 day
                    secure: true,
                    sameSite: 'Strict',
                });

                // Update global authentication state
                setAuthenticated(true);  // Update authentication status in App.js

                // Redirect user based on their role
                if (role === 'teacher') {
                    navigate('/teacher-upload');
                } else if (role === 'student') {
                    navigate('/student-upload');
                } else {
                    throw new Error('Unknown role, unable to navigate');
                }
            } catch (error) {
                // Log the error for debugging, but avoid exposing sensitive info
                console.error('Error during OAuth token exchange:', error);
                setError(error.message || 'Authentication failed. Please try again.');
            } finally {
                setLoading(false); // Stop loading spinner
            }
        };

        // Extract the authorization code and role from the URL query parameters
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        const role = urlParams.get('role');

        // Ensure both code and role are present in the URL
        if (code && role) {
            exchangeCodeForToken(code, role);
        } else {
            setError('Authorization code or role is missing from the URL');
        }
    }, [navigate, setAuthenticated]);

    // Display error, spinner, or nothing based on state
    return (
        <div className="flex justify-center mt-4">
            {loading ? (
                <Oval
                    height={100}
                    width={100}
                    color="#4fa94d"
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#4fa94d"
                    strokeWidth={10}
                    strokeWidthSecondary={2}
                />
            ) : error ? (
                <p className="text-red-500 font-bold">{error}</p>
            ) : null}
        </div>
    );
}
