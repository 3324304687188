import React from 'react';
import gradeLogo from './grade_1.png';
import Footer from './Footer';
import './fonts.css';
import './App.css';
import './header.css';
import logo from './easygradepfp.png';
import { useNavigate } from 'react-router-dom';
import Navbar from './Navbar';

export default function DashboardEN() {
    document.body.style.backgroundColor = "#f3fbfb";
    document.body.style.display = 'flex';
    document.body.style.paddingTop = "150px";
    const navigate = useNavigate();

    // Function to redirect to the TorS (Teacher or Student) page
    const redirectToTorS = () => {
        navigate("/teacher-or-student");
    };

    return (
        <>
            <Navbar title={'Home Page'}/>
            <div className='flex w-screen justify-center'>
                <img src={gradeLogo} className='w-[60%] sm:w-[40%]' alt="Grade Logo"></img>
            </div>
            <div className = 'flex justify-center items-center w-screen'>
            <p className=' text-[6vw] sm:text-[4vw] lg:text-2xl max-w-[80%] text-darkBlue font-Tajawal text-center' style={{ marginTop: "10vh" }}>
                Welcome to Easy Grade, where our AI-powered tool is revolutionizing the grading process, combining efficiency & detailed analysis to transform the way writing assignments are assessed.<br/>
            </p>
                    </div>
                    <div className = 'flex justify-center items-center w-screen'>
            <p className='text-[6vw] sm:text-[4vw] lg:text-2xl max-w-[80%] lg:pl-0 text-darkBlue pb-10vh font-Tajawal text-center'><br/>
                By providing instant, comprehensive feedback, we are dedicated to enhancing educational outcomes and empowering tutors; enabling them to focus on fostering student success and excellence.
            </p>
                    </div>
            <div className='w-full inline-flex justify-center mt-[10vh]'>
                <button className='dashboard-button justify-center items-center flex rounded-lg px-[40px] sm:px-[100px] py-[5px] text-[20px] text-darkBlue bg-whiter mr-[1vw] border-[2px] border-lighterBlue' onClick={redirectToTorS}>
                    Login
                </button>
                <button className='dashboard-button justify-center items-center flex rounded-lg px-[30px] sm:px-[86px] py-[5px] text-[20px] text-darkBlue bg-whiter mr-[1vw] border-[2px] border-lighterBlue' onClick={redirectToTorS}>
                    Sign Up
                </button>
            </div>
            <Footer />
        </>
    );
}
